import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from 'react-helmet'

class NavCard extends React.Component {
  render() {
    const { pos, cardTitle, cardText, cardButton, photo, link } = this.props;
    let dir;
    let mar;

    if (pos === "right") {
      dir = "md:flex-row"
      mar = "md:-ml-48"
    } else if (pos === "left") {
      dir = "md:flex-row-reverse"
      mar = "md:-mr-48"
    }

    return (
      <div className={"mt-10 mb-10 flex flex-col items-center justify-center " + dir}>
        <div className="lg:max-w-3xl w-full -mb-20 md:mb-0">
          <Img fluid={photo} />
        </div>
        <div className={"relative md:w-11/12 flex shadow-xl bg-white rounded-sm flex flex-col justify-between leading-normal lg:p-10 p-5 lg:max-w-2xl w-11/12 " + mar}>
          <div className="text-center md:text-left mb-2">
            <h1 className="text-blue-500 font-bold md:text-2xl text-xl">{cardTitle}</h1>
            <p className="md:mt-5 mt-3 text-gray-700 text-lg text-base mb-6">{cardText}</p>
            <Link to={link} className="no-underline bg-blue-500 hover:bg-blue-400 text-white font-light py-3 px-5 rounded">{cardButton}</Link>
          </div>
        </div>
      </div>
    )
  }
}

const IndexPage = (props) => (
  <>
    <Helmet title="Bad Homburg | IRT" defer={false} />
    <Layout>
      <section className="relative w-full overflow-hidden h-400">
        <Img fluid={props.data.imageFive.childImageSharp.fluid} className="h-full" />
        <div className="flex items-center justify-center absolute inset-0">
          <div className="lg:max-w-5xl lg:w-7/12 w-11/12 text-center">
            <h1 className="text-white font-semibold md:text-3xl text-xl mb-5">Rückenschmerzen sind für viele Menschen eine Qual. Wir wollen sie langfristig davon befreien.</h1>
            <Link to="/unser-konzept" className="bg-blue-500 hover:bg-blue-400 text-white font-medium py-3 px-5 rounded no-underline">Mehr erfahren</Link>
          </div>
        </div>
      </section>
      <section className="lg:w-7/12 w-11/12 text-center mt-24 mb-14">
        <h1 className="text-white font-light md:text-3xl text-xl">“Ein Training, welches Rückenschmerzen lindern und Operationen vermeiden kann.<br></br>Individuell, persönlich und nachhaltig.”</h1>
        <p className="text-white font-thin md:text-lg text-m mt-4">~ Dr. Jan Wolff</p>
      </section>
      <section className="w-10/12 md:w-7/12 my-24 lg:my-32 shadow-2xl border-white border-2">
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/mDYSSFd47hY"
            frameBorder={0}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>
      <section className="lg:w-11/12 lg:max-w-6xl">
        <h1 className="text-center text-white font-semibold lg:text-3xl text-2xl">Lernen Sie uns kennen</h1>
        <NavCard link="/unser-konzept" cardText="In 9 Wochen und insgesamt 18 Trainingseinheiten zu einem neuen Lebensgefühl." cardTitle="Unsere medizinische Trainingstherapie" cardButton="Unser Konzept" pos="right" photo={props.data.imageOne.childImageSharp.fluid} />
        <NavCard link="/team" cardText="Dürfen wir vorstellen? Das Team der IRT Bad Homburg." cardTitle="Wir zusammen" cardButton="Das Team" pos="left" photo={props.data.imageTwo.childImageSharp.fluid} />
        <NavCard link="/haeufige-fragen" cardText="Hier finden Sie die meist gestellten Fragen und ihre Antworten. Falls nicht, stellen Sie einfach ihre Fragen direkt an uns." cardTitle="Fragen? Wir haben Antworten" cardButton="Zu den Antworten" pos="right" photo={props.data.imageThree.childImageSharp.fluid} />
        <NavCard link="/kontakt" cardText="Hier können sie herausfinden, wie sie uns am kontaktieren und wo wir unsere Standorte haben." cardTitle="Unser Kontakt" cardButton="Kontakt" pos="left" photo={props.data.imageFour.childImageSharp.fluid} />
      </section>
    </Layout>
  </>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 60) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "photo1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "photo2.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "photo3.jpg" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "photo4.jpg" }) {
      ...fluidImage
    }
    imageFive: file(relativePath: { eq: "photo5.jpg" }) {
      ...fluidImage
    }
  }
`;